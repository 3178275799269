

.process-steps {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  height: 100%;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 40px;
  }
}

.process-step {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__separator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;

      @media (min-width: 1200px) {
        width: auto;
        height: 400px;
        margin: 0;
      }

        &::after, &::before {
            content: ' ';
            position: absolute;
            height: 1px;
            width: calc(50% - 20px);
            top: 50%;
            background-color: #dddddd;

            @media (min-width: 1200px) {
                width: 1px;
                height: 120px;
                inset-inline-start: calc(50% - 2px);
            }
        }

        &::before {
            inset-inline-start: 0;

            @media (min-width: 1200px) {
                top: calc(50% - 140px);
                inset-inline-start: calc(50% - 2px);
                bottom: auto;
                width: 1px;
            }
        }

        &::after {
            inset-inline-end: 0;
            @media (min-width: 1200px) {
                top: auto;
                bottom: calc(50% - 140px);
                inset-inline-end: auto;
            }
        }
    }

    &__separator-icon {
        margin-top: 10px;
        margin-bottom: 10px;
        transform: rotate(-90deg);
        color: var(--global-primary-background-dark);
      display: block;
      z-index: 4;

        @media (min-width: 1200px) {
            transform: none;
            width: 45px;
            height: 16px;
        }
    }

    &__icon {
        display: inline-block;
        width: 34px;
        height: 34px;
        flex: 34px;
        height: auto;

        img {
            width: 34px;
            height: auto;
            object-fit: contain;
        }
    }

    &__title {
        margin-top: -5px;
        font-weight: bold;
        color: var(--global-primary-background-dark);
    }

    &__content-item {
        text-align: center;

        &--strong {
            font-weight: 500;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15px;

    }

    &__cancel {
      margin-top: 16px;
        font-weight: bold;
        font-size: var(--font-s);
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.desktop-cancel-link {
    margin-top: 50px;
    text-align: center;

    a {
        color: var(--global-link-color);
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            text-decoration: none;

        }
    }
}

.success-header {
    color: var(--global-success-background);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.success-icon-wrapper{

}

.success-icon {

}

.replace-success-title {
    font-size: var(--font-l);
    font-weight: bold;
    color: var(--global-success-colors);
    margin-top: 12px;
    margin-bottom: 30px;
}

.success-content {
    counter-reset: test;

    .success-list {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 25px 0;
    }

    .success-list-item {
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &__number {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 26px;
            width: 26px;
            flex: 0 0 26px;
            border: 2px solid var(--global-color);
            border-radius: 50%;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            line-height: 0px;
            color: var(--global-color);
        }

        &__content {
            display: inline-block;
            text-align: justify;
            flex: 100%;
        }
    }
}

.back-button-gruop {
    margin-top: 20px;
}
